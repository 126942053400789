<!--
 * @Author: 智客云网络科技
 * @Date: 2022-02-06 18:36:10
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-08 16:04:50
 * @Description: 请输入文件描述信息
 * @FilePath: \src\views\Home\Home.vue
-->
<template>
  <div class="kc-app">
    <van-nav-bar
      title="首页"
      :fixed="true"
      :placeholder="true"
      :z-index="500"
      :safe-area-inset-top="true"
    />

    <!-- 滚动图 -->
    <div class="swiper">
      <van-swipe class="my-swipe" :autoplay="3000">
        <van-swipe-item v-for="(image, index) in swiperList" :key="index">
          <img v-lazy="image" />
        </van-swipe-item>
      </van-swipe>
    </div>

    <!-- 公告栏 -->
    <!-- <div class="noticebar">
      <nut-noticebar
        :text="noticebar"
        color="rgb(217, 80, 11)"
        background="rgb(251, 248, 220)"
      ></nut-noticebar>
    </div> -->

    <!-- 快捷操作栏 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperList: [
        "https://storage.360buyimg.com/jdc-article/NutUItaro34.jpg",
        "https://storage.360buyimg.com/jdc-article/NutUItaro2.jpg",
        "https://storage.360buyimg.com/jdc-article/welcomenutui.jpg",
        "https://storage.360buyimg.com/jdc-article/fristfabu.jpg",
      ],
      noticebar:
        "华为畅享9新品即将上市，活动期间0元预约可参与抽奖，赢HUAWEI WATCH等好礼，更多产品信息请持续关注！",
    };
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
}

.my-swipe .van-swipe-item img {
  width: 100%;
}
</style>

<style>
.nut-swiper-item img {
  width: 100vw;
}
</style>
